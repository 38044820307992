import * as React from 'react';
import clsx from 'clsx';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { FluidObject } from 'gatsby-image';
import { Typography } from './Typography';
import { TReactFCR } from '../lib/types/utils';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { GatsbyImageLink } from './GatsbyImageLink';

type ClassKey = 'paragraph' | 'image';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    paragraph: { marginBottom: theme.baseline.blockMarginBottom },
    image: { boxShadow: theme.shadows[6], borderRadius: theme.shape.borderRadius }
}));

export type TGatsbyImageGridItem = {
    originalSrc: string;
    image: FluidObject;
    aspectRatio: number;
    caption?: React.ReactNode;
};

export type TGatsbyImageGridP = {
    rows: TGatsbyImageGridItem[][];
    spacing?: GridProps['spacing'];
    paragraph?: boolean;
    caption?: string;
};

export function GatsbyImageGrid(props: TGatsbyImageGridP): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { rows, caption, spacing = 0, paragraph = false }: TGatsbyImageGridP = props;
    return (
        <div className={clsx({ [styles.paragraph]: paragraph })}>
            <Grid container direction='column' spacing={spacing}>
                {rows.map((r: TGatsbyImageGridItem[], i: number) => (
                    <Grid key={i} item container wrap='nowrap' spacing={spacing}>
                        {r.map((item: TGatsbyImageGridItem) => (
                            <Grid key={item.image.src} item style={{ flex: `${item.aspectRatio} 1 0%` }}>
                                <GatsbyImageLink
                                    to={item.originalSrc}
                                    disableUnderline
                                    imageProps={{ className: styles.image, fluid: item.image }}
                                />
                                {item.caption && (
                                    <Typography variant='caption' align='center' component='p'>
                                        {item.caption}
                                    </Typography>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>
            {caption && <Typography variant='caption' align='center' component='p'>{caption}</Typography>}
        </div>
    );
}
