import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { GQLProjectGtmCtrlPageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { Link } from '../../components/Link';
import { GatsbyImageGrid, TGatsbyImageGridItem } from '../../components/GatsbyImgGrid';
import { GatsbyImageLink } from '../../components/GatsbyImageLink';

type ClassKey = 'main' | 'image' | 'imageContainer';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
    image: {
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[6]
    },
    imageContainer: { display: 'flex', justifyContent: 'center' }
}));

export type TProjectGtmCtrlP = {
    data: GQLProjectGtmCtrlPageQuery;
};

function ProjectGtmCtrl(props: TReactFCP<TProjectGtmCtrlP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsYaml, messageAction, dialog, slashCommandHelp }: GQLProjectGtmCtrlPageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectGtmCtrlPageQuery['projectsYaml']> = projectsYaml!;
    const dialogAndHelpMenuImgs: TGatsbyImageGridItem[] = [
        {
            originalSrc: dialog!.childImageSharp!.original?.src!,
            image: dialog!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: dialog!.childImageSharp!.fluid!.aspectRatio!,
            caption: 'The points dialog'
        },
        {
            originalSrc: slashCommandHelp?.childImageSharp?.original?.src!,
            image: slashCommandHelp!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: slashCommandHelp!.childImageSharp!.fluid!.aspectRatio!,
            caption: 'Learning how to play the game using the help menu'
        }
    ];
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                In 2019 our club of merry motorhead misfits at{` `}
                <Link variant='external' to='https://gtmotorsports.org/'>Gran Touring Motorsports</Link> upgraded our
                tech in a big way. We built a new website, became a real corporate entity, and migrated our{` `}
                <i>mind boggling</i> number of chats (from 60+ people) from Google Hangouts, Facebook Messenger, SMS,
                and email lists to Slack. I was in charge of getting people up to speed and familiar with
                Slack as I was the one leading the migration effort and had lots of experience using and writing{` `}
                <Link variant='internal' to='/projects/quinn/'>apps for Slack</Link>
                {` at `}
                <Link variant='internal' to='/career/'>Qntfy</Link>.
            </Typography>
            <Typography paragraph>
                One of the common threads across our chats and real life conversations was the notion of awarding or
                taking away "points" from people for various reasons. The origin story of how this
                points game came to be is contested and clouded in mystery, but its all done in the name of
                schenanigans and fun.
            </Typography>
            <Typography paragraph>
                Points can be awarded anonymously on the club's website to any member for any
                reason and at the end of each year we hand out a trophy to the member with the greatest total. Since
                most points are awarded during our discussions online, I saw an opportunity to build a Slack app to
                increase engagement by enabling participation in the game as conversations were unfolding.
            </Typography>
            <GatsbyImageLink
                to={messageAction?.childImageSharp?.original?.src!}
                className={styles.imageContainer}
                disableUnderline
                imageProps={{
                    className: styles.image,
                    style: {
                        maxWidth: messageAction!.childImageSharp!.fluid!.presentationWidth!, height: 'auto', flex: 1
                    },
                    alt: 'Awarding points using the message action',
                    fluid: messageAction!.childImageSharp!.fluid! as FluidObject,
                }}
            />
            <Typography variant='caption' color='light' align='center' paragraph>
                Awarding points using the message action
            </Typography>
            <Typography paragraph>
                The app exposes both a{` `}
                <Link variant='external' to='https://api.slack.com/interactivity/slash-commands'>
                    <code>/points</code> command
                </Link>
                {` and a `}
                <Link variant='external' to='https://api.slack.com/interactivity/actions'>message action</Link> that
                members can use to open a dialog containing the the form to submit points. Since <code>message actions
                </code> have access to the context of the message, the <code>reason</code> field within the dialog is
                automatically filled with the message content.
            </Typography>
            <GatsbyImageGrid paragraph spacing={4} rows={[dialogAndHelpMenuImgs]} />
            <Typography paragraph>
                The slash command also includes documentation on the game and helpful links to the website via the{` `}
                <code>/points help</code> subcommand. All data for the game is stored and hosted by the club's website
                which also hosts the API that GTM Ctrl communicates with to administer the game.
            </Typography>
            <Typography paragraph>
                Finally, the app itself was built in a modular way to encourage and enable the creation of future Slack
                and CLI integrations for club services, games, and more. Our club's primary method of communication is
                Slack and we have other services like classifieds, a blog, merchandise, and events which could easily
                be extended into Slack. I had a lot of fun building this in under a week and look forward to adding to
                it in the future!
            </Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectGtmCtrlP>): TReactFCR => (
    <Page component={ProjectGtmCtrl} cprops={props} />
);

export const query = graphql`
    query ProjectGtmCtrlPage {
        sitePage(internalComponentName: {eq: "ComponentProjectsGtmCtrl"}) {
            path
        }
        projectsYaml(pid: {eq: "gtm-ctrl"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
        messageAction: file(relativePath: {eq: "images/projects/gtm-ctrl/message-action.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                }
                original {
                    src
                }
            }
        }
        dialog: file(relativePath: {eq: "images/projects/gtm-ctrl/dialog.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        slashCommandHelp: file(relativePath: {eq: "images/projects/gtm-ctrl/slash-command-help.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
    }
`;
